
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'trainee registration',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      districts: [],
      subDistricts: [],
      subPermanentDistrict: [],
      subHomeSubDistrict: [],
      empDistricts: [],
      empHomeDistricts: [],
      trainee: {
        entity_id: '',
        training_institute_id: '',
        course_info_id: '',
        tranche_id: '',
        batch_info_id: '',
        reference_number: '',
        trainee_name: '',
        gender: '',
        nid: '',
        bcn: '',
        date_of_birth: '',
        present_address: '',
        present_post_code: '',
        present_district: '',
        present_upazilla: '',
        photo: '',
        per_address: '',
        per_post_code: '',
        per_district: '',
        per_upazilla: '',
        home_district: '',
        home_upazilla: '',
        mobile: '',
        email: '',
        alternative_mobile: '',
        religion: '',
        ethnic_group: '',
        highest_class_completed: '',
        highest_class_completed_year: '',
        is_employed: '',
        year_of_experience: '',
        family_monthly_income: '',
        is_physically_challenged: '',
        challenge_remarks: '',
        mother_name: '',
        mother_education_level: '',
        mother_occupation: '',
        father_name: '',
        father_education_level: '',
        father_occupation: '',
        father_annual_income: '',
        have_family_owned_home: '',
        have_family_owned_land: '',
        number_of_siblings: '',
        bank_name: '',
        bank_branch_name: '',
        bank_routing_no: '',
        bank_account_number: '',
        account_type: '',
        x_bank_id: '',
        x_mobile_bankings_id: '',
        x_agent_banking_id: '',
        confirmaccount_no: '',
        dup_enrollment : 1,
      },
      url: "",
      base64_image: "",
      nid_alternative: "",
      server_nid: "",
      institutes: [],
      eligible_duplicate_course_ids: [],
      associations: [],
      tranches: [],
      courses: [],
      batches: [],
      banks: [],
      mobile_banks: [],
      agent_banks: [],
      ethnicGroups: [],
      traineeData: [] as any,
      loading: false,
      validated: 0,
      load: false,
      showNidVerifyForm: false,
      showNidField: false,
      showBCNField: false,
      showTraineeForm: false,
      showtrainingProviderNotice: false,
      currentYear: new Date().getFullYear(),
    };
  },
  async created() {
    await this.associationList();
    await this.getTranches();
    // await this.getDivision();
    await this.getDistrictForEmp();
    await this.getEthnicGroup();
    await this.getBankInfo();
    await this.getAgentBankInfo();
    await this.getMobileBankInfo();
  },
  methods: {
    async formSubmit() {
      let formData = new FormData();
      let user_id = VueCookieNext.getCookie('_seip_user');
      formData.set('base64_image', this.base64_image);
      formData.set('photo', this.trainee.photo);
      formData.set('nid_alternative', this.nid_alternative);
      formData.set('server_nid', this.server_nid);
      formData.set('user_id', user_id.id);
      for (var key in this.trainee) {
        formData.set(key, this.trainee[key]);
      }
      this.loading = true;
      await ApiService.post('trainee/save', formData)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            if (response.data.status == 'error') {
              this.loading = false;
              Swal.fire({
                title: 'Error!',
                html: response.data.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
            } else {
              Swal.fire({
                title: 'Success!',
                text: response.data.data,
                icon: 'success',
                buttonsStyling: false,
                confirmButtonText: 'Ok',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(() => {
                
              });
                this.base64_image = "";
                this.nid_alternative = "";
                this.server_nid = "";
                this.trainee = {
                  entity_id: this.trainee.entity_id,
                  training_institute_id: this.trainee.training_institute_id,
                  course_info_id: this.trainee.course_info_id,
                  tranche_id: this.trainee.tranche_id,
                  batch_info_id: this.trainee.batch_info_id,
                  reference_number: '',
                  trainee_name: '',
                  gender: '',
                  nid: '',
                  bcn: '',
                  date_of_birth: '',
                  present_address: '',
                  present_post_code: '',
                  present_district: '',
                  present_upazilla: '',
                  photo: '',
                  per_address: '',
                  per_post_code: '',
                  per_district: '',
                  per_upazilla: '',
                  home_district: '',
                  home_upazilla: '',
                  mobile: '',
                  email: '',
                  alternative_mobile: '',
                  religion: '',
                  ethnic_group: '',
                  highest_class_completed: '',
                  highest_class_completed_year: '',
                  is_employed: '',
                  year_of_experience: '',
                  family_monthly_income: '',
                  is_physically_challenged: '',
                  challenge_remarks: '',
                  mother_name: '',
                  mother_education_level: '',
                  mother_occupation: '',
                  father_name: '',
                  father_education_level: '',
                  father_occupation: '',
                  father_annual_income: '',
                  have_family_owned_home: '',
                  have_family_owned_land: '',
                  number_of_siblings: '',
                  bank_name: '',
                  bank_branch_name: '',
                  bank_routing_no: '',
                  bank_account_number: '',
                  account_type: '',
                  x_bank_id: '',
                  x_mobile_bankings_id: '',
                  x_agent_banking_id: '',
                  confirmaccount_no: '',
                  dup_enrollment : 1,
                };
              this.url = '' as any;
              this.trainee.trainee_name = '';
              this.trainee.mother_name = '';
              this.trainee.father_name = '';
              this.trainee.gender = '';
              this.trainee.present_address = '';
              this.trainee.per_address = '';
              this.trainee.present_post_code ='';
              this.trainee.per_post_code = '';
              this.trainee.per_post_code = '';
              this.base64_image =  '';
              this.nid_alternative =  '';
              this.server_nid = '';
              this.trainee.dup_enrollment  = '' as any;

              this.showNidVerifyForm = false;
              this.showTraineeForm = false;
              this.showNidField = false;
              this.showBCNField = false;
            }
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        });
    },
    traineePhoto(e) {
      this.trainee.photo = e.target.files[0];
      this.url = URL.createObjectURL(this.trainee.photo);
      this.base64_image = '';
    },
    async getTranches() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEthnicGroup() {
      await ApiService.get('configurations/ethnic_group/list')
        .then((response) => {
          this.ethnicGroups = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getBankInfo() {
      await ApiService.get('configurations/bank/list')
        .then((response) => {
          this.banks = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getAgentBankInfo() {
      await ApiService.get('configurations/bank/list?agent_bank=1')
        .then((response) => {
          this.agent_banks = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getMobileBankInfo() {
      await ApiService.get('configurations/mobile_bank/list')
        .then((response) => {
          this.mobile_banks = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrictForEmp() {
      await ApiService.get('configurations/geo_district/list')
        .then((response) => {
          this.empDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getSubDistrict() {
      await ApiService.get(
        'configurations/geo_upazila/list?district=' +
          this.trainee.present_district
      )
        .then((response) => {
          this.subDistricts = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getPermanentSubDistrict() {
      await ApiService.get(
        'configurations/geo_upazila/list?district=' + this.trainee.per_district
      )
        .then((response) => {
          this.subPermanentDistrict = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getHomeSubDistrict() {
      await ApiService.get(
        'configurations/geo_upazila/list?district=' + this.trainee.home_district
      )
        .then((response) => {
          this.subHomeSubDistrict = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      let entity_id = this.trainee.entity_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      this.load = true;
      await ApiService.get(
        this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
      )
        .then((response) => {
          this.associations = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async batchChange() {
      this.trainee.date_of_birth = '';
      this.trainee.nid = '';
      this.trainee.bcn = '';
      this.showTraineeForm = false;
      this.showNidField = false;
      this.showBCNField = false;
      this.showTraineeForm = false;
    },

    async trainingInstitute() {
      this.trainee.training_institute_id = '';
      this.trainee.course_info_id = '';
      this.trainee.batch_info_id = '';
      this.trainee.date_of_birth = '';
      this.trainee.nid = '';
      this.trainee.bcn = '';
      this.showTraineeForm = false;
      this.showNidField = false;
      this.showBCNField = false;
      this.showTraineeForm = false;
      this.load = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          '?entity_id=' +
          this.trainee.entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.institutes = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseList() {
      this.trainee.course_info_id = '';
      this.trainee.batch_info_id = '';
      this.trainee.date_of_birth = '';
      this.trainee.nid = '';
      this.trainee.bcn = '';
      this.showTraineeForm = false;
      this.showNidField = false;
      this.showBCNField = false;
      this.showTraineeForm = false;
      let entity_id = this.trainee.entity_id;
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      this.load = true;
      await ApiService.get(
        'course/list?entity_id=' +
          entity_id +
          '&tranche=' +
          this.trainee.tranche_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.courses = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getBatchList() {
        this.trainee.batch_info_id = '';
        this.trainee.date_of_birth = '';
        this.trainee.nid = '';
        this.trainee.bcn = '';
        this.showTraineeForm = false;
        this.showNidField = false;
        this.showBCNField = false;
        this.showTraineeForm = false;
      let entity_id = this.trainee.entity_id;
      let institute_info_id = this.trainee.training_institute_id;
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      this.load = true;
      await ApiService.get(
        'batch/list?entity_id=' +
          entity_id +
          '&course_info_id=' +
          this.trainee.course_info_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.batches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async showNidVerify() {
      this.load = true;
      let id = this.trainee.batch_info_id;
        this.trainee.date_of_birth = '';
        this.trainee.nid = '';
        this.trainee.bcn = '';
        this.showTraineeForm = false;
        this.showNidField = false;
        this.showBCNField = false;
        this.showTraineeForm = false;
      if (this.trainee.batch_info_id) {
        await ApiService.get('trainee/enroll_verify?batch_info_id=' + id)
          .then((response) => {
            if (response.data.status == 'error') {
              this.load = false;
              Swal.fire({
                title: 'Error!',
                html: response.data.message,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
            } else {
              this.showNidVerifyForm = true;
              this.load = false;
            }
          })
          .catch(({ response }) => {
            console.log(response);
            this.load = false;
          });
      } else {
        this.load = false;
        Swal.fire({
          title: 'Warning!',
          html: 'Please select batch first.',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    verifyBCN() {
      if (this.trainee.bcn) {
        this.showTraineeForm = true;
      }
    },

    async verifyNid() {
      let nid = this.trainee.nid;
      let nid_digits = nid.toString().length;
      if (nid_digits == 10 || nid_digits == 13 || nid_digits == 17) {
        if (nid_digits == 13) {
          this.checkNidLocalDB(nid);
          const dob = new Date(this.trainee.date_of_birth);
          let year = dob.getFullYear();
          nid = year + nid;
          this.checkNidLocalDB(nid);
        } else {
          this.checkNidLocalDB(nid);
        }
      } else {
        Swal.fire({
          title: 'Error!',
          html: 'NID must be 10/13/17 digits',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },
    async checkNidLocalDB(nid) {
      this.load = true;
      this.server_nid =  nid;
      await ApiService.get("trainee/search?nid=" + nid)
        .then((response) => {
          if (response.data.data) {
            this.load = false;
            var msg = '';
            if (response.data.data.allow_dup_enrollment == 1) {
              let allow_course = 0;
              this.eligible_duplicate_course_ids = JSON.parse("[" + response.data.data.eligible_duplicate_course_ids + "]");
              for (
                var i = 0;
                i < this.eligible_duplicate_course_ids.length;
                i++
              ) {
                if (
                  this.eligible_duplicate_course_ids[i] ==
                  this.trainee.course_info_id
                ) {
                  allow_course = 1;
                }
              }
              if (allow_course == 1) {
               this.checkNidServer();
              } else {
                msg = 'This registered trainee (' +  response.data.data.registration_number + ') is not eligible to enroll for this course.';
              }
            } else {
              if (response.data.data.registration_number) {
                msg =
                  'Duplicate trainee exists (Existing Trainee Registration no: ' +
                  response.data.data.registration_number +
                  ')';
              } else {
                msg =
                  'Duplicate trainee exists (Existing Trainee Reference no: ' +
                  response.data.data.reference_number +
                  ')';
              }
            }
            if (msg != '') {
              Swal.fire({
                title: 'Error!',
                html: msg,
                icon: 'error',
                buttonsStyling: false,
                confirmButtonText: 'Close',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              });
            }
          } else {
            this.checkNidServer();
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async checkNidServer() {
      if (this.trainee.nid && this.trainee.date_of_birth) {
        await ApiService.get(
          'trainee/nid_check?nid=' +
            this.trainee.nid +
            '&dob=' +
            this.trainee.date_of_birth +
            '&entity_id=' +
            this.trainee.entity_id +
            '&training_institute_id=' +
            this.trainee.training_institute_id +
            '&course_info_id=' +
            this.trainee.course_info_id +
            '&batch_info_id=' +
            this.trainee.batch_info_id
        )
          .then((response) => {
            this.load = false;
            if (response.data.status == 'success') {
              this.trainee.trainee_name = response.data.data.trainee_name;
              this.trainee.mother_name = response.data.data.mother_name;
              this.trainee.father_name = response.data.data.father_name;
              this.trainee.gender = response.data.data.gender;
              this.trainee.present_address = response.data.data.present_address;
              this.trainee.per_address = response.data.data.per_address;
              this.trainee.present_post_code =
                response.data.data.present_post_code;
              this.trainee.per_post_code = response.data.data.per_post_code;
              this.trainee.per_post_code = response.data.data.per_post_code;
              this.url = response.data.data.photo;
              this.base64_image =  response.data.data.photo;
              this.nid_alternative =  response.data.data.pin;
              this.showTraineeForm = true;
              this.validated = 1;
              this.server_nid = this.trainee.nid;
              this.trainee.dup_enrollment  = response.data.data.dup_enrollment;
            } else {

              if(response.data.message){
                  Swal.fire({
                    title: 'Error!',
                    html: response.data.message,
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: 'Close',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                    },
                  });
              }else{
                  Swal.fire({
                    title: 'Error!',
                    html: 'NID data not found. Please check your date of birth and nid.',
                    icon: 'error',
                    buttonsStyling: false,
                    confirmButtonText: 'Close',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                    },
                  });
              }
            
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
    async checkDob() {
      if (this.trainee.date_of_birth) {
        let dob = this.trainee.date_of_birth;
        let age = this.getAge(dob);
        await ApiService.get("course/show/" + this.trainee.course_info_id)
        .then((response) => {
          if (response.data.data) {
            if(age < response.data.data.min_age){
               Swal.fire({
                  title: 'Warning!',
                  html: 'Age must be atleast '+response.data.data.min_age +' years.',
                  icon: 'warning',
                  buttonsStyling: false,
                  confirmButtonText: 'Close',
                  customClass: {
                    confirmButton: 'btn btn-warning',
                  },
                });
            }else if(age > response.data.data.max_age){
               Swal.fire({
                  title: 'Warning!',
                  html: 'Age should not be more than '+response.data.data.max_age +' years.',
                  icon: 'warning',
                  buttonsStyling: false,
                  confirmButtonText: 'Close',
                  customClass: {
                    confirmButton: 'btn btn-warning',
                  },
                });
            }else{
              if (age > 19) {
                this.showNidField = true;
              } else {
                this.showNidField = true;
                this.showBCNField = true;
              }
            }
          }
        })
      }
    },

    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
  },
  setup() {
    const TrainerSchema = Yup.object().shape({
      email: Yup.string()
        .email('Must be valid email')

        .label('Email'),
      // //presentDistrict: Yup.string().required().label("Present District"),
      // name: Yup.string().required().label("Name"),
      // father_name: Yup.string().required().label("Father Name"),
      // mother_name: Yup.string().required().label("Mother Name"),
      // trainer_image: Yup.string().required().label("Trainer Photo"),
      // religion: Yup.string().required().label("Religion"),
      // gender: Yup.string().required().label("gender"),
      // mobile: Yup.number().required().min(11).label("Mobile"),
      // ref_id: Yup.number().required().label("Reference Id"),
      // mother_edu: Yup.string().required().label("Mother's Education Level"),
      // mother_occ: Yup.string().required().label("Mother's Occupation"),

      // branchName: Yup.string().required().label("Branch Name"),
      // routingNo: Yup.string().required().label("Routing No"),
      // account_no: Yup.string().required().label("Account Number"),
      // present_districts: Yup.string().required().label("Present District"),
      // permanent_districts: Yup.string().required().label("Permanent District"),
      // permanent_upazilla: Yup.string().required().label("Permanent Upazilla"),
      // present_upazilla: Yup.string().required().label("Present Upazilla"),
      // is_employee: Yup.string().required().label("Is Employee"),
      // is_physically: Yup.string().required().label("Is Physically"),
      // remarks: Yup.string().required().label("Remarks"),
      // passing_year: Yup.string().required().label("Passing Year"),
      // family_own_home: Yup.string().required().label("Family Owned Home"),
      // family_own_land: Yup.string().required().label("Family Owned Land"),
      // bank_account_type: Yup.string().required().label("Bank Account Type"),
      // mobile_bank: Yup.string().required().label("Bank Name"),
      // confirmaccount_no: Yup.string()
      //   .required()
      //   .label("Confirm Account Number"),
      // permanent_districts: Yup.string().required().label("Permanent District"),

      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        )
        .label('Password'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Password didn't match!")
        .label('Confirm Password'),
    });
    return {
      TrainerSchema,
    };
  },
});
